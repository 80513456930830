const pageURL = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  userManagement: '/user-management',
  supplierAccountManagement: '/supplier-account-management',
  moduleManagement: '/module-management',
  pif: '/pif',
  infoShare: '/information-share',
  suppliersOpening: '/suppliers-opening',
  health: '/health',
  barcodeUpload: '/pif/barcode-upload',
  barcodeSupplierSend: '/pif/supplier-barcode-send',
  barcodeSendApproval: '/pif/barcode-send-approval',
  barcodeApproveOrReject: '/pif/barcode-approve-or-reject',
  changeUser: '/change-user',
  documentTypeManagement: '/document-type-management',
  currentTypeManagement: '/current-type-management',
  dataEntyControl: '/data-entry-control',
  companyDocumentManagement: '/company-document-management'
};

export default pageURL;
