import pageURL from '../utils/pageUrls';

export const LOCAL_KEYS = Object.freeze({
  Lang: 'SCP_LANG',
  User: 'SCP_USER',
  Token: 'SCP_TOKEN'
});

export const FileSettings = Object.freeze({
  CompressionQuality: 0.6,
  CompressionMaxLength: 2,
  Height: 2400,
  Length: 2400
});

export const SORT_TYPE = Object.freeze({
  '-1': 'Desc',
  1: 'Asc'
});

export const MODULE_CODES_AND_PATH = [
  { code: 'PIF', to: pageURL.pif, key: 'pif' },
  { code: 'CURRENT_ACCOUNT_OPENING', to: pageURL.suppliersOpening, key: 'suppliersOpening' },
  { code: 'BILGI_PAYLASIM', to: pageURL.infoShare, key: 'infoShare' },
  { code: 'MODULE', to: pageURL.moduleManagement, key: 'moduleManagement' },
  { code: 'USER', to: pageURL.userManagement, key: 'userManagement' },
  {
    code: 'CARI',
    to: pageURL.supplierAccountManagement,
    key: 'supplierAccountManagement'
  }
];

export const PROFILE_TYPE = Object.freeze({
  Watsons: 1,
  Supplier: 2
});

export const PRODUCT_BARCODE_STATUS = Object.freeze({
  Draft: 0,
  NotSent: 1,
  Sent: 2,
  Expired: 3,
  Rejected: 4
});

export const PRODUCT_BARCODE_FILE_STATUS = Object.freeze({
  Pending: 0,
  NotAvailiable: 1,
  Uploaded: 2,
  Confirmed: 3,
  Rejected: 4,
  WaitingApproval: 5
});

export const SUPPLIER_PIF_STATUS = Object.freeze({
  Waiting: 0,
  Continued: 1,
  Uploaded: 2,
  Sent: 3,
  ReEvaluation: 4
});

export const COUNSELLOR_PIF_STATUS = Object.freeze({
  None: 0,
  PendingEvaluation: 1,
  EvaluationProcessContinuing: 2,
  Confirmed: 3,
  Rejected: 4
});

export const ProductBarcodeFileType = Object.freeze({
  InnerPackaging: 0,
  OuterPackaging: 1,
  TurkishLabeling: 2,
  ProductPicture: 3,
  ProductionMethod: 4,
  GmpCompliance: 5,
  Formulation: 6,
  IfraCertificateOfConformity: 7,
  AllergenReport: 8,
  ParfumAromaCoaTds: 9,
  ParfumAromaMsds: 10,
  RawMaterialCoaTds: 11,
  RawMaterialMsds: 12,
  RawMaterialOther: 13,
  FinishedProductTds: 14,
  FinishedProductMsds: 15,
  MicrobiologicallyTest: 16,
  ChallengeTest: 17,
  StabilityTest: 18,
  PackagingTds: 19,
  PackagingMsds: 20,
  Impurities: 21,
  ProofOfClaims: 22,
  SafetyAssessor: 23,
  SafetyAssessmentReport: 24,
  Other: 25,
  AhaBhaConcentrationDeclaration: 26
});

export const ApplicableStatus = Object.freeze({
  NotApplicable: 0,
  Applicable: 1
});

export const NewUploadOrDeclarationStatus = Object.freeze({
  None: 0,
  Declaration: 1,
  NewUpload: 2
});

export const DOCUMENT_STATUS = Object.freeze({
  Created: 1,
  DocumentsRequested: 2,
  DocumentsShared: 3,
  MissingOrIncorrectDocumentsPending: 4,
  DataEntryUnderReview: 5,
  ManagerHeadAuditReview: 6,
  Approved: 7,
  Rejected: 8,
  Closed: 9,
  Completed: 10
});

export const CURRENT_ACCOUNT_APPROVAL_STATUS = Object.freeze({
  None: 0,
  Waiting: 1,
  Approval: 2,
  Rejected: 3
});

export const CURRENT_ACCOUNT_DOCUMENT_STATUS = Object.freeze({
  Waiting: 0,
  WaitingApproval: 1,
  Approval: 2,
  Rejected: 3,
  Updloaded: 4
});
